import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from "../utils/AxiosInterceptor"

import AuthTokenModel from '../model/AuthTokenModel';
import LoginRequestModel from '../model/LoginRequestModel';

interface AuthTokenState {
  token: AuthTokenModel | null;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: AuthTokenState = {
  token: null,
  loading: false,
  error: null
};

// Methed to login which calls /aip/auth/token with login creds and returns the auth token
export const login = createAsyncThunk('authToken/login', async (loginRequest: LoginRequestModel) => {
  const response = await axiosInstance.post('abreviado/api/auth/token', loginRequest);
  return response.data;
});

// Methed to clear the token
export const clearToken = createAsyncThunk('authToken/clear', async () => {
  return null;
});

const authTokenSlice = createSlice({
  name: 'authToken',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.token = null;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.token = null;
        state.error = action.error.message || 'An error occured while authenticating';
      })
      .addCase(clearToken.pending, (state) => {
        state.loading = true;
        state.token = null;
        state.error = null;
      })
      .addCase(clearToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
        state.error = null;
      });
  }
});

export default authTokenSlice.reducer;