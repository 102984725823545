import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, CircularProgress, Card, CardHeader, CardContent } from '@mui/material';

import './QuestionnaireDashboardPage.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

import { Link as RouterLink } from 'react-router-dom';

function QuestionnaireDashboardPage() {

  const [authToken, setAuthToken] = useLocalStorage('authToken', null);
  const navigate = useNavigate();

  useEffect(() => {
    // redirect to login if there's no auth token
    if (authToken === null) {
      navigate("/login");
    }

    // scroll to the top of the page on render
    window.scrollTo(0, 0)

  }, [authToken, navigate])

  return (
    <Box className="page-body">
        <Card className="questionnaire-card">
          <CardHeader className="title" title={<Typography variant="h4" component="h4" className="title-text">Selecione um Questionário</Typography>}></CardHeader>
          <CardContent className="content">
            <Button className="questionnaire-button" to="/questionnaire?id=1" component={RouterLink}>Questionário de Burnout</Button>
            <Button className="questionnaire-button" to="/questionnaire?id=2" component={RouterLink}>Questionário de Ansiedade</Button>
            <Button className="questionnaire-button" to="/questionnaire?id=3" component={RouterLink}>Questionário de Depressão</Button>
          </CardContent> 
        </Card>
    </Box>
  );
}

export default QuestionnaireDashboardPage;