import { useState, useEffect, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearToken, login } from "../../slices/AuthTokenSlice";
import { clearUser, createUser } from "../../slices/UserSlice";
import { AppDispatch } from "../../store";
import { Button, Card, CardContent, CardHeader, CircularProgress, TextField, Typography, Box, Link, InputLabel, FormControl, Tooltip, Zoom, Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MuiTelInput } from 'mui-tel-input'

import './SignupPage.css';
import useLocalStorage from "use-local-storage";

import { getAllLanguages } from "../../slices/LanguageSlice";
import { getAllSubscriptionTiers } from "../../slices/SubscriptionTierSlice";

// TODO: merge all the css to use the css file if possible
// TODO: at least compress the select sx
function SignupPage() {
  // set up router navigation
  const navigate = useNavigate();

  // input styling
  const inputSx = {
    // Set icon color
    "& .MuiSvgIcon-root": {
      color: "var(--accent-1)"
    },
    // Root class for the input field
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor:  "var(--accent-1) !important",
          borderWidth: "2px"
        }
      },
      "&:hover:not(.Mui-focused)": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--accent-1)",
        },
      },
    },
    // Class for the label of the input field
    "& .MuiInputLabel-outlined": {
      "&.Mui-focused": {
        color:  "var(--accent-1)",
      }
    }
  }

  // define internal state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [acceptedTOS, setAcceptedTos] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [nativeLanguage, setNativeLanguage] = useState('');
  
  // these cannot be asynchronous
  let emailError = false;
  let passwordError = false;
  let firstNameError = false;
  let lastNameError = false;
  let acceptedTOSError = false;
  let whatsappNumberError = false;
  let subscriptionTierError = false;
  let nativeLanguageError = false;

  // states to draw on form fields
  const [emailErrorState, setEmailErrorState] = useState(false);
  const [passwordErrorState, setPasswordErrorState] = useState(false);
  const [firstNameErrorState, setFirstNameErrorState] = useState(false);
  const [lastNameErrorState, setLastNameErrorState] = useState(false);
  const [acceptedTOSErrorState, setAcceptedTosErrorState] = useState(false);
  const [whatsappNumberErrorState, setWhatsappNumberErrorState] = useState(false);
  const [subscriptionTierErrorState, setSubscriptionTierErrorState] = useState(false);
  const [nativeLanguageErrorState, setNativeLanguageErrorState] = useState(false);

  // use dispatch to connect us to the controller
  const dispatch = useDispatch<AppDispatch>();

  const token = useSelector((state: any) => state.authToken.token); 
  const userError = useSelector((state: any) => state.user.error);
  const userLoading = useSelector((state: any) => state.user.loading);

  const subscriptionTiers = useSelector((state: any) => state.subscriptionTier.subscriptionTiers);
  const subscriptionTiersLoading = useSelector((state: any) => state.subscriptionTier.loading);
  const subscriptionTiersError = useSelector((state: any) => state.subscriptionTier.error);

  const languages = useSelector((state: any) => state.language.languages);
  const languagesLoading = useSelector((state: any) => state.language.loading);
  const languagesError = useSelector((state: any) => state.language.error);


  // clear the error if it's there on new page load
  if (userError && token) {
    dispatch(clearUser());
    dispatch(clearToken());
  }

  // call dispatch to get the list of subscription tiers
  if (!subscriptionTiers && !subscriptionTiersLoading && !subscriptionTiersError) {
    dispatch(getAllSubscriptionTiers())
  }

  // call dispatch to get the list of languages
  if (!languages && !languagesLoading && !languagesError) {
    dispatch(getAllLanguages())
  }

  const user = useSelector((state: any) => state.user.user);

  const [authToken, setAuthToken] = useLocalStorage('authToken', null);

  const buildSubscriptionTierSelector = () => {
    let subscriptionTierMenuItems = subscriptionTiers?.map((subscriptionTierItem: any, index: number) => {
      return (
        <MenuItem key={"subscription-tier-menu-item-" + subscriptionTierItem.id} value={String(subscriptionTierItem.refName)}>{subscriptionTierItem.displayName}</MenuItem>
      )
    });

    return subscriptionTierMenuItems;
  }

  const buildLanguageSelector = () => {
    let languageMenuItems = languages?.map((languageItem: any, index: number) => {
      return (
        <MenuItem key={"language-menu-item-" + languageItem.id} value={String(languageItem.refName)}>{languageItem.displayName}</MenuItem>
      )
    });

    return languageMenuItems;
  }

  useEffect(() => {

    if(authToken != null) {
      navigate('/');
    }

    if(user) {
      const loginRequest = {
        userId: email,
        password: password
      };

      dispatch(login(loginRequest));
    }

    if(token) {
      setAuthToken(token);
    }

  }, [token, user, authToken, email, password, navigate, dispatch, setAuthToken]);

  // call the signup reducer with the signup request
  const handleSignup = (event: any) => {

    // prevent the page from refreshing on form submit
    event.preventDefault();

    let formErrors = false;

    // validate form fields
    emailError = !email;
    passwordError = !password;
    firstNameError = !firstName;
    lastNameError = !lastName;
    acceptedTOSError = !acceptedTOS;
    whatsappNumberError = !whatsappNumber;
    subscriptionTierError = !subscriptionTier;
    nativeLanguageError = !nativeLanguage;

    // validate password format
    if (!passwordError) {
      var passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{8,20}$");
      if (!password.match(passwordRegex)) {
        passwordError = true;
      }
    }

    // validate email format
    if (!emailError) {
      var emailRegex = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
      if (!email.match(emailRegex)) {
        emailError = true;
      }
    }

    // set the error states
    setEmailErrorState(emailError);
    setPasswordErrorState(passwordError);
    setFirstNameErrorState(firstNameError);
    setLastNameErrorState(lastNameError);
    setAcceptedTosErrorState(acceptedTOSError);
    setWhatsappNumberErrorState(whatsappNumberError);
    setSubscriptionTierErrorState(subscriptionTierError);
    setNativeLanguageErrorState(nativeLanguageError);

    formErrors = passwordError || emailError || firstNameError || lastNameError || acceptedTOSError || whatsappNumberError || subscriptionTierError || nativeLanguageError;

    // once we validate the form, we can dispatch the signup request
    // TODO: update form to take new fields
    if (!formErrors) {

      var formattedWhatsappNumber = whatsappNumber.replace(/\s/g, "");

      console.log("Creating user with email: " + email + " and password: " + password);
      console.log("First Name: " + firstName + " Last Name: " + lastName);
      console.log("WhatsApp Number: " + formattedWhatsappNumber);
      console.log("Subscription Tier: " + subscriptionTier);
      console.log("Native Language: " + nativeLanguage);
      console.log("Accepted TOS: " + acceptedTOS);



      const createUserRequest = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        acceptedTOS: acceptedTOS,
        whatsappNumber: formattedWhatsappNumber,
        subscriptionTier: subscriptionTier,
        nativeLanguage: nativeLanguage
      };

      // make the login request
      dispatch(createUser(createUserRequest));
    }
  }

  // function to route the user to the signup page
  const goToLogin = () => {
    dispatch(clearUser())
    navigate("/login");
  }

  return (
    <Box className="page-body">
      <Card className="signup-card">
        <CardHeader className="title" title={<Typography variant="h4" component="h2">Create Your Account</Typography>}></CardHeader>
        <CardContent className="content">
          <form className="signup-form" onSubmit={handleSignup}>
            {userError? <Typography variant="body1" className="action-error-message">An account with this email already exists</Typography> : ''}
            <Box className="input-row">
              <TextField 
                onChange={(e) => setFirstName(e.target.value)} 
                className="signup-input"
                label="First Name" 
                variant="outlined"
                error={!!firstNameErrorState}
                sx={inputSx}
                required>
              </TextField>
              <TextField 
                onChange={(e) => setLastName(e.target.value)} 
                className="signup-input"
                label="Last Name" 
                variant="outlined"
                error={!!lastNameErrorState}
                sx={inputSx}
                required>
              </TextField>
            </Box>

            <Box className="input-row">
              <TextField 
                onChange={(e) => setEmail(e.target.value)} 
                className="signup-input" 
                label="E-mail" 
                variant="outlined"
                error={!!emailErrorState}
                sx={inputSx}
                required>
              </TextField>
              <MuiTelInput 
              className="signup-input"
              label="WhatsApp Number" 
              sx={inputSx}
              error={!!whatsappNumberErrorState}
              variant="outlined"
              onChange={(number) => setWhatsappNumber(number)}
              value={whatsappNumber} 
              required/>
            </Box>

            <Box className="input-row">
              <FormControl sx={inputSx} className="signup-input" required>
                <InputLabel id="subscription-tier-label">Subscription Tier</InputLabel>
                  <Select
                    className="select-input"
                    labelId="subscription-tier-label"
                    label="Subscription Tier"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 210 } } }}
                    error={!!subscriptionTierErrorState}
                    value={subscriptionTier}
                    onChange={(e) => setSubscriptionTier(e.target.value)}>
                      {buildSubscriptionTierSelector()}
                  </Select>
              </FormControl>
              <FormControl sx={inputSx} className="signup-input" required>
                <InputLabel id="native-language-label">Native Language</InputLabel>
                  <Select
                    className="select-input"
                    labelId="native-language-label"
                    label="Native Language"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 210 } } }}
                    error={!!nativeLanguageErrorState}
                    value={nativeLanguage}
                    onChange={(e) => setNativeLanguage(e.target.value)}>
                      {buildLanguageSelector()}
                  </Select>
              </FormControl>
            </Box>

            <Box className="input-row">
              <Tooltip title="8-20 characters, at least 1 lower case letter, 1 uppercase letter, 1 number, 1 special character (@$!%*?&)" TransitionComponent={Zoom}>
                <TextField
                  className="signup-input" 
                  onChange={(e) => setPassword(e.target.value)} 
                  label="Password" 
                  variant="outlined"
                  error={!!passwordErrorState}
                  sx={inputSx}
                  type="password"
                  autoComplete="on"
                  required>
                </TextField>
              </Tooltip>
              <Box className="signup-input-tos">
                  <Checkbox
                    className="tos-checkbox"
                    checked={acceptedTOS}
                    onChange={(e) => setAcceptedTos(e.target.checked)}
                    required/>
                  <Typography variant="body1" className="tos-text">
                    By checking this box, you accept the <Link href="/terms-of-service" target="_blank" className="tos-link">Terms of Service</Link> and <Link href="/privacy" target="_blank" className="tos-link">Privacy Policy</Link>
                  </Typography>

              </Box>
            </Box>

            <Button type="submit" className="create-account-button" variant="contained" size="large" color="primary"> 
              {userLoading? <CircularProgress className="signup-circular-progress"></CircularProgress> : <Typography>Create Account</Typography>}
            </Button>
            <Typography variant="body1" className="login-text">Already have an account? <Button onClick={() => goToLogin()} sx={{textTransform: 'none'}}  className="login-link">Log In</Button></Typography>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default SignupPage;
