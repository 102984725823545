import axios from 'axios';

export const axiosInstance = axios.create()

let ACCESS_TOKEN = null;

// TDOO: rewrite this to pull the token from the store in each slice api call

//Configure AxiosInstance
function configureAxios (params:any) {
  // Set params data into local variables and use
  // that variable inside function like below.
  let accessToken = params.accessTokenString;

  axiosInstance.interceptors.request.use( config => {
    // Add the access token if we have it
    config.headers.Authorization =  accessToken;

    // return the updated config
    return config;
    
  }, error => {
    return Promise.reject(error)
  });
  
}

export default configureAxios;