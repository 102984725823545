import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, CircularProgress, Card } from '@mui/material';

import './QuestionnaireResultsPage.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSurvey } from '../../slices/UserSurveySlice';
import { Link as RouterLink } from 'react-router-dom';


function QuestionnaireResultsPage() {

  // call dispatch to get the survey details
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const userSurveyId = Number(searchParams.get('userSurveyId'));

  const [authToken, setAuthToken] = useLocalStorage('authToken', null);
  const navigate = useNavigate();

  const userSurvey = useSelector((state: any) => state.userSurvey.userSurvey);
  const userSurveyError = useSelector((state: any) => state.userSurvey.error);

  const evaluation = userSurvey?.evaluation;
  const score = String(userSurvey?.score);
  const assessmentLevel = userSurvey?.assessmentLevel;
  const feedback = userSurvey?.feedback;

  const awaitingUserSurvey = userSurvey == null && userSurveyError == null;

  useEffect(() => {
    // scroll to the top of the page on render
    window.scrollTo(0, 0)

    // redirect to login if there's no auth token
    if (authToken === null) {
      navigate("/login");
    }

    if(userSurvey == null || userSurvey.id !== userSurveyId) {
      // TODO: redirect to 404 on invalid user survey id
      dispatch(getUserSurvey({authToken:authToken, userSurveyId:userSurveyId}));
    }

  }, [authToken, userSurvey, userSurveyId, dispatch, navigate])

  return (
    <Box className="page-body">
      {awaitingUserSurvey?
      // TODO: make a loading component to use across the site
        <Box className="survey-results-loading-box">
          <CircularProgress className="load-survey-results-circle-progress" size="10rem"></CircularProgress>
        </Box>:
        <Box className="results-box">
          <Box className="results-card">
            <Box className="results-title-box">
              <Typography className="results-title" variant="h3">Resultados</Typography>
            </Box>
            <Box className="score-box" style={{color: "var(--level-" + assessmentLevel + ")"}}>
              <Typography className="score-text" variant="h2">{score}</Typography>
              <Typography className="score-text" variant="h4">{evaluation}</Typography>
            </Box>
            <Box className="feedback-alignment-box">
              <Box className="spacer"></Box>
              <Box className="feedback-box">
                <Typography className="feedback-text" variant="body1">{feedback}</Typography>
              </Box>
            </Box>
          </Box>
          <Button className="return-home-button" to="/" component={RouterLink}>Pagina Inicial</Button>
        </Box>
      }  
    </Box>
  );
}

export default QuestionnaireResultsPage;