import { Box } from '@mui/material';
import LoginCardComponent from '../../global/login-card-component/LoginCardComponent';

import './LoginPage.css';


function LoginPage() {
  return (
    <Box className="page-body">
        <LoginCardComponent />
    </Box>
  );
}

export default LoginPage;