import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';

import 'dayjs/locale/pt-br';

import GavelIcon from '@mui/icons-material/Gavel';
import './TosPage.css';

function TosPage() {

  return (
    <Box className="page-body">
      <Card className="terms-card">
        <CardHeader className="terms-title" title={<Typography variant="h3" component="h3">Abreviado Terms of Service</Typography>}></CardHeader>
        <CardContent className="terms-content">
          <Box className="terms-icon-box">
            <GavelIcon className="terms-icon" />
          </Box>
          <Typography className="effective-date" variant="h5" component="h5">Last Updated: September 18, 2024</Typography>
          <div>
            <Typography variant="h5" component="h5">1. Acceptance of Terms</Typography>
            <Typography variant="body1" component="div">
              Welcome to Abreviado a product owned by NoComment LLC! 
              <ul>
                <li><strong>
                  By accessing or using Abreviado, you agree to comply with and be bound by 
                  these Terms of Service and our Privacy Policy. If you do not agree, you 
                  must not use our service.
                </strong></li>
              </ul>
            </Typography>

            <Typography variant="h5" component="h5">2. Service Description</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  Abreviado is a messaging service that allows users to send messages to 
                  our WhatsApp account for translation transcription and summarization of 
                  text and audio files. The service includes multiple subscription tiers, 
                  including a free tier with limited usage.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">3. User Accounts</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  To use our service, you must create an account. You agree to 
                  provide accurate and complete information during registration 
                  and to keep this information up-to-date. Information includes
                  a valid email address and phone number owned by you.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">4. Subscription Tiers and Payments</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  <strong>Free Tier</strong>: Users can send one free message capable of being processed by Abreviado per day.
                </li>
                <li>
                  <strong>Paid Tiers</strong>: Users can subscribe to various plans that allow processing of a fixed number of messages per month at a specified cost, as defined by the subscription tier. Details on pricing and specific tiers, is provided during the subscription process.
                </li>
                <li>
                  <strong>Pricing Changes</strong>: We will notify users at least 30 days in advance of any changes to pricing for subscription tiers. Notifications will be sent via your provided email, whatsapp or through in-app notifications.
                </li>
                <li>
                  Payments are due on a recurring monthly basis for paid subscriptions. You may cancel your subscription at any time, and the cancellation will take effect at the end of the current billing cycle.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">5. Cancellations</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  You can cancel your subscription at any time. Upon cancellation, 
                  your subscription will remain active until the end of the billing 
                  month for which you have paid.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">6. Message Storage</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  We may store message data for audit and training purposes. By 
                  using our service, you consent to the storage and processing of 
                  your message data as outlined in our Privacy Policy.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">7. User Conduct</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  You agree not to use the service for any unlawful purpose or 
                  in a way that could damage, disable, overburden, or impair the 
                  service. You will not attempt to gain unauthorized access to 
                  any part of the service or other systems or networks connected 
                  to the service. Abreviado may not process content that it deems
                  to be offensive or illegal.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">8. Intellectual Property</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  All content, trademarks, and other intellectual property rights in the 
                  service are owned by NoComment LLC or its licensors. You are granted a 
                  limited, non-exclusive, non-transferable license to use the service in 
                  accordance with these Terms.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">9. Limitation of Liability</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  To the fullest extent permitted by law, Abreviado and NoComment LLC shall 
                  not be liable for any direct, indirect, incidental, special, consequential, 
                  or punitive damages arising from your use of the service. Your sole remedy 
                  for dissatisfaction with the service is to stop using it.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">10. Indemnification</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  You agree to indemnify and hold harmless Abreviado, NoComment LLC, 
                  and their affiliates, and their respective officers, directors, 
                  employees, and agents from any claims, damages, losses, liabilities, 
                  costs, or expenses (including reasonable attorneys' fees) arising 
                  out of your use of the service or violation of these Terms.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">11. Modifications to the Terms</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  We may modify these Terms from time to time. We will provide notice of any 
                  material changes and your continued use of the service after such changes 
                  constitutes your acceptance of the new Terms.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">12. Governing Law</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  These Terms shall be governed by and construed in accordance 
                  with the laws of the Commonwealth of Virginia, without regard to 
                  its conflict of law principles. Any disputes arising under these 
                  Terms shall be resolved in the courts located in 
                  Richmond, Virginia.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">13. International Use</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  If you access the service from outside the United States, you are 
                  responsible for compliance with local laws and regulations.
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">14. Contact Information</Typography>
            <Typography variant="body1" component="div"><ul>
                <li>
                  For any questions regarding these Terms of Service, please contact us 
                  at penn@nocomment.dev.
                </li>
            </ul>
            </Typography>

          </div>
        </CardContent>
      </Card>
    </Box>
  );
}

export default TosPage;