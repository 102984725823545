import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, CircularProgress } from '@mui/material';

import './QuestionnairePage.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useLocalStorage from 'use-local-storage';
import { submitSurvey, clearSurveySubmissionId } from "../../slices/UserSurveySlice";
import { AppDispatch } from '../../store';
import { getSurveyDetails } from '../../slices/SurveyDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';

import parse from 'html-react-parser';
import SurveyRequestModel from '../../model/SurveyRequestModel';


function QuestionnairePage() {

  const radioSx = {
    color: 'var(--primary-color)',
    marginLeft: '10px',
    '&.Mui-checked': {
    color: 'var(--accent-1)'
    }
  }

  const formLabelSx = {
    color: 'var(--primary-color)',
    textAlign: 'left',
    marginTop: '10px',
    fontWeight: 'bold'
  }

  const radioGroupSx = {
    padding: '5px 0px',
    margin: '10px'
  }

  // call dispatch to get the survey details
  const dispatch = useDispatch<AppDispatch>();

  const [authToken, setAuthToken] = useLocalStorage('authToken', null);
  const navigate = useNavigate();

  const surveyDetails = useSelector((state: any) => state.surveyDetails.survey);
  const surveyDetailsError = useSelector((state: any) => state.surveyDetails.error);
  const surveyDetailsLoading = useSelector((state: any) => state.surveyDetails.loading);

  const surveySubmissionId = useSelector((state: any) => state.userSurvey.surveySubmissionId);
  const userSurveyLoading = useSelector((state: any) => state.userSurvey.loading);

  const awaitingSurvey = surveyDetails == null && surveyDetailsError == null;

  const [radioErrorState, radioNameErrorState] = useState(-1);

  // todo, clear surveyResponse when the page is displayed

  // get the survey id from the url parameter
  // TODO: show an error if the id parameter is empty / redirect to not found page
  const [searchParams, setSearchParams] = useSearchParams();
  const surveyId = Number(searchParams.get('id'));
  const surveyDescription = String(surveyDetails?.description);

  const surveyTitle = surveyDetails?.displayName;

  useEffect(() => {
    // redirect to login if there's no auth token
    if (authToken === null) {
      navigate("/login");
    }

    if(surveyDetails == null || surveyDetails.id !== surveyId) {
      dispatch(getSurveyDetails({authToken:authToken, surveyId:surveyId}));
    }

    if(surveySubmissionId != null) {
      let submissionId = surveySubmissionId;
      dispatch(clearSurveySubmissionId());
      navigate("/questionnaire-results?userSurveyId=" + submissionId);
    }

    // scroll to the top of the page on render
    window.scrollTo(0, 0)

  }, [authToken, surveyDetails, surveyId, surveySubmissionId, dispatch, navigate])

  const buildSurveyForm = () => {
    let surveyForm = surveyDetails?.questions.map((question: any, index: number) => {
      return (
        <Box className="question-box" key={"question-" + String(question.id) + "-box"}>
          <FormLabel key={"question-" + String(question.id) + "-label"} sx={formLabelSx}>{String(index + 1) + ". " + question.questionText}</FormLabel>
          <RadioGroup id={"radio-group-"+ String(question.id)} key={String(question.id)} sx={radioGroupSx}>
            {buildAnswerField(question.id, question.answers)}
          </RadioGroup>
        </Box>
      )
    });

    return surveyForm;
  }

  const buildAnswerField = (questionId: number, answers: any) => {
    let surveyAnswers = answers.map((answer: any, index: number) => {
      return (
            <FormControlLabel className="radio-label" key={String(answer.id)} name={String(questionId)} value={String(answer.id)} control={<Radio sx={radioSx} />} label={answer.answerText}/>
      )
    });

    return surveyAnswers;    
  }

  // function to route the user to the signup page
  const handleSurveySubmit = (event: any) => {
    // prevent the page from refreshing on form submit
    event.preventDefault();

    // clear all previous error boxes
    let errorBoxes = document.querySelectorAll('.error-box');
    errorBoxes.forEach((errorBox) => {
      errorBox.classList.remove('error-box');
    });

    // build the surveyRequestModel to submit the survey to the backend
    let surveyRequestModel: SurveyRequestModel = {
      surveyId: surveyId,
      answers: []
    };

    let emptyRadios = [] as any[];

    // loop through the radio groups and grab the selected answers
    surveyDetails?.questions.forEach((question: any) => {
      let selectedAnswer = (document.querySelector('input[name="' + question.id + '"]:checked') as HTMLInputElement)?.value;
      if (selectedAnswer == null) {
        radioNameErrorState(question.id);
        emptyRadios.push(question.id);
      }
      else {  
        // get the value from the answer
        surveyRequestModel.answers.push({
          questionId: question.id,
          answerId: Number(selectedAnswer)
        });
      }
    });

    // if there are empty readios, draw a red border around each one
    if (emptyRadios.length > 0) {
      
      emptyRadios.forEach((questionId) => {
        // get the question box by key
        let questionBox = document.getElementById("radio-group-"+ questionId);
        questionBox?.classList.add('error-box');
      });

      // scroll up to the first error box
      let firstErrorBox = document.querySelector('.error-box');
      firstErrorBox?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

      return;
    }

    dispatch(submitSurvey({authToken:authToken, surveyRequest:surveyRequestModel}));
  }

  return (
    <Box className="page-body">
      {awaitingSurvey?
        <Box className="survey-loading-box">
          <CircularProgress className="load-survey-circle-progress" size="10rem"></CircularProgress>
        </Box>:
        <Box>
          <Box className="survey-title-box">
            <Typography className="survey-title" variant="h4">{surveyTitle}</Typography>
          </Box>
          <Box className="survey-description-box">
            <Typography className="survey-description" variant="body1">{parse(surveyDescription)}</Typography>
          </Box>
          <form className="survey-form" onSubmit={handleSurveySubmit}>
            {buildSurveyForm()}
            <Button type="submit" className="submit-survey-button" variant="contained" size="large" color="primary"> 
            {userSurveyLoading? <CircularProgress className="signup-circular-progress"></CircularProgress>: <Typography>Enviar Questionário</Typography>}
            </Button>
          </form>   
        </Box>
      }  
    </Box>
  );
}

export default QuestionnairePage;