import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from "../utils/AxiosInterceptor"

import LanguageModel from '../model/LanguageModel';

interface LanguageState {
  languages: LanguageModel[] | null;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: LanguageState = {
  languages: null,
  loading: false,
  error: null
};

// Methed to retrieve a survey details with a given ID
// Thunks can only take one parameter so they have to be restructured
// TODO: redo this once we have a global axios handler
export const getAllLanguages = createAsyncThunk('language/getAll', async () => {
  const response = await axiosInstance.get('abreviado/api/language/list');
  return response.data;
});

// Methed to clear the user
export const clearLanguage = createAsyncThunk('language/clear', async () => {
  return null;
});

const languageSlice: any = createSlice({
  name: 'surveyDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLanguages.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.languages = null;
      })
      .addCase(getAllLanguages.fulfilled, (state, action) => {
        state.loading = false;
        state.languages = action.payload;
        state.error = null;
      })
      .addCase(getAllLanguages.rejected, (state, action) => {
        state.loading = false;
        state.languages = null;
        state.error = action.error.message || 'An error occured while retrieving languages';
      });
  }
});

export default languageSlice.reducer;