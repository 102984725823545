import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';

import 'dayjs/locale/pt-br';

import PolicyIcon from '@mui/icons-material/Policy';
import './PrivacyPage.css';

function PrivacyPage() {

  return (
    <Box className="page-body">
      <Card className="privacy-card">
        <CardHeader className="privacy-title" title={<Typography variant="h3" component="h3">Abreviado Privacy Policy</Typography>}></CardHeader>
        <CardContent className="privacy-content">
          <Box className="privacy-icon-box">
            <PolicyIcon className="privacy-icon" />
          </Box>
          <Typography className="effective-date" variant="h5" component="h5">Effective Date: August 9, 2024</Typography>
          <div>
            <Typography variant="h5" component="h5">1. Introduction</Typography>
            <Typography variant="body1" component="div">Welcome to No Comment LLC, operating under the DBA Abreviado. At No Comment LLC, we are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, process, and safeguard your personal information, and how we ensure its confidentiality and security. By using our services, you agree to the practices described in this policy.</Typography>

            <Typography variant="h5" component="h5">2. Information We Collect</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Personal Information:</strong>
                    <Typography variant="body1" component="div"><ul>
                        <li><strong>WhatsApp Phone Numbers:</strong> Collected via the WhatsApp API.</li>
                        <li><strong>WhatsApp Message Data:</strong> Including message content and metadata, collected via the WhatsApp API.</li>
                        <li><strong>Full Name:</strong> Provided directly by users.</li>
                        <li><strong>Email Address:</strong> Provided directly by users.</li>
                    </ul></Typography>
                </li>
                <li><strong>Generic Usage Audit Data:</strong>
                    <Typography variant="body1" component="div"><ul>
                        <li><strong>Login Times:</strong> Information about when users log in to our services.</li>
                        <li><strong>Service Usage:</strong> Data on how users interact with our services.</li>
                    </ul></Typography>
                </li>
            </ul></Typography>

            <Typography variant="h5" component="h5">3. Collection Methods</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>WhatsApp API:</strong> To collect WhatsApp phone numbers and message data.</li>
                <li><strong>Facebook SDK:</strong> For authentication and other service integrations.</li>
                <li><strong>Direct Collection:</strong> Information collected directly from users via our website or applications.</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">4. Use of Information</Typography>
            <Typography variant="body1" component="div">We use the collected information for:</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Performance Analytics:</strong> To analyze how our software is used.</li>
                <li><strong>Software Improvements:</strong> To enhance our services and their functionality.</li>
                <li><strong>Model Training:</strong> To improve our internal models and algorithms.</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">5. Data Sharing and Disclosure</Typography>
            <Typography variant="body1" component="div">We do not share your personal data with third parties except as follows:</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Service Providers:</strong> We are required to register with WhatsApp/Facebook/Meta to use their services. This registration is essential for service functionality.</li>
                <li><strong>Legal Requirements:</strong> In the event of a legal obligation, we may disclose data to authorities. We handle all such requests on a case-by-case basis and ensure that only the minimum required data is released with proper legal representation.</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">6. Data Retention and Deletion</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Retention Period:</strong> We retain your data as long as necessary to fulfill the purposes outlined in this policy.</li>
                <li><strong>Data Deletion:</strong> You may request the deletion of your data at any time by emailing us from the email address associated with your account at <a href="mailto:penn@nocomment.dev">penn@nocomment.dev</a>. We will process your request and ensure that your data is deleted from our systems.</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">7. Security Measures</Typography>
            <Typography variant="body1" component="div">We implement robust security measures to protect your data:</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Encryption:</strong> All data is encrypted in transit and at rest.</li>
                <li><strong>Access Control:</strong> We enforce a strict role-based access policy to ensure that only authorized personnel access your data.</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">8. User Rights</Typography>
            <Typography variant="body1" component="div">Under applicable data protection laws, you have the following rights:</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Access and Correction:</strong> You have the right to access the personal data we hold about you and request corrections. To exercise this right, email us at <a href="mailto:penn@nocomment.dev">penn@nocomment.dev</a>. Your data will be provided in the format it was received (e.g., zip file containing JSON, images, audio, video) via an S3 signed URL.</li>
                <li><strong>Opt-Out:</strong> The only way to opt out of our data processing is to terminate your account.</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">9. Cookies and Tracking Technologies</Typography>
            <Typography variant="body1" component="div">We do not use cookies or similar tracking technologies.</Typography>

            <Typography variant="h5" component="h5">10. International Data Transfers</Typography>
            <Typography variant="body1" component="div">Data may be transferred internationally through:</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Meta’s End-to-End Encrypted WhatsApp API:</strong> For data exchanged via WhatsApp.</li>
                <li><strong>Encrypted HTTPS:</strong> For data transmitted via our website.</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">11. Changes to This Privacy Policy</Typography>
            <Typography variant="body1" component="div">We may update this Privacy Policy from time to time. Any changes will be posted on our website, and the updated policy will be effective from the date of posting.</Typography>

            <Typography variant="h5" component="h5">12. Contact Information</Typography>
            <Typography variant="body1" component="div">For any questions or concerns regarding this Privacy Policy, please contact us at:</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>Email:</strong> <a href="mailto:penn@nocomment.dev">penn@nocomment.dev</a></li>
                <li><strong>Mailing Address:</strong> 440 Monticello Ave Ste 1802 #234231, Norfolk VA 23510</li>
            </ul></Typography>

            <Typography variant="h5" component="h5">13. Compliance with Regulations</Typography>
            <Typography variant="body1" component="div"><ul>
                <li><strong>GDPR (General Data Protection Regulation):</strong> If you are located in the European Union, we comply with the GDPR requirements, including ensuring the lawful basis for processing personal data, providing access to data, and fulfilling requests for data deletion and correction.</li>
                <li><strong>CCPA (California Consumer Privacy Act):</strong> For California residents, we adhere to the CCPA provisions, which include providing the right to know what personal information is collected, the right to request deletion of personal data, and the right to opt-out of the sale of personal information (although we do not sell personal information).</li>
                <li><strong>CalOPPA (California Online Privacy Protection Act):</strong> We follow the CalOPPA requirements by providing a comprehensive privacy policy, including details about our data collection practices, user rights, and contact information.</li>
            </ul></Typography>

            <Typography variant="body1" component="div">By using our services, you acknowledge that you have read and understood this Privacy Policy and agree to our data practices.</Typography>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PrivacyPage;