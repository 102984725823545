import { configureStore } from '@reduxjs/toolkit';
import authTokenReducer from './slices/AuthTokenSlice';
import userReducer from './slices/UserSlice';
import surveyDetailsReducer from './slices/SurveyDetailsSlice';
import userSurveyReducer from './slices/UserSurveySlice';
import languageReducer from './slices/LanguageSlice';
import subscriptionTierReducer from './slices/SubscriptionTierSlice';

const store = configureStore({
  reducer: {
    authToken: authTokenReducer,
    user: userReducer,
    language: languageReducer,
    subscriptionTier: subscriptionTierReducer,
    // TODO: Delete these
    surveyDetails: surveyDetailsReducer,
    userSurvey: userSurveyReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export default store;