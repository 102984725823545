import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from "../utils/AxiosInterceptor"

import SubscriptionTierModel from '../model/SubscriptionTierModel';

interface SubscriptionTierState {
  subscriptionTiers: SubscriptionTierModel[] | null;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: SubscriptionTierState = {
  subscriptionTiers: null,
  loading: false,
  error: null
};

// Methed to retrieve a survey details with a given ID
// Thunks can only take one parameter so they have to be restructured
// TODO: redo this once we have a global axios handler
export const getAllSubscriptionTiers = createAsyncThunk('subscriptionTier/getAll', async () => {
  const response = await axiosInstance.get('abreviado/api/subscription-tier/list');
  return response.data;
});

// Methed to clear the user
export const clearSubscriptionTier = createAsyncThunk('subscriptionTier/clear', async () => {
  return null;
});

const subscriptionTierSlice: any = createSlice({
  name: 'surveyDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptionTiers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.subscriptionTiers = null;
      })
      .addCase(getAllSubscriptionTiers.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionTiers = action.payload;
        state.error = null;
      })
      .addCase(getAllSubscriptionTiers.rejected, (state, action) => {
        state.loading = false;
        state.subscriptionTiers = null;
        state.error = action.error.message || 'An error occured while retrieving the subscription tiers';
      });
  }
});

export default subscriptionTierSlice.reducer;