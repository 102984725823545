import { Box, Typography, Button, CircularProgress, Card } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { PlayCircleOutline, PauseCircleOutline, Replay, Margin } from "@mui/icons-material";
import Carousel from 'react-material-ui-carousel'

import './HomePage.css';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import useLocalStorage from 'use-local-storage';

import { GraphicEq } from "@mui/icons-material";

import sound from '../../assets/audio/demo-voice-message.wav'

import Equalizer from "r3f-equalizer"

function HomePage() {

  const user = useSelector((state: any) => state.user.user);
  const [authToken, setAuthToken] = useLocalStorage('authToken', null);

  const awaitingLogin = user == null && authToken !== null;
  const isLoggedIn = user !== null;

  const audioRef = useRef() as any;

  const [paused, setPaused] = useState(true);

  var items = [
    {
        language: "English",
        text: "Message Summary: Just back from an amazing coastal trip! Explored local markets, relaxed on secluded beaches, hiked to stunning views, kayaked to sea caves, and enjoyed fresh seafood and award-winning wines. A rejuvenating break from daily life!"
    },
    {
        language: "Spanish",
        text: "Mensaje Resumen: Regresé de un viaje increíble a un pueblo costero con calles empedradas, mercados locales, playas solitarias y aguas cristalinas. Hicimos senderismo, kayak, probamos deliciosos tacos de pescado y vinos premiados. Fue un descanso rejuvenecedor del estrés diario."
    },
    {
      language: "German",
      text: "Nachrichten-Zusammenfassung: Ich bin gerade von einem unglaublichen Küsten-Trip zurückgekehrt! Erkundeten lokale Märkte, entspannten auf abgelegenen Stränden, wanderten zu atemberaubenden Aussichten, paddelten zu Meereshöhlen und genossen frischen Fisch und preisgekrönte Weine. Ein erfrischender Ausbruch aus dem täglichen Stress!"
    },
    {
      language: "Russian",
      text: "Сообщение-резюме: Только что вернулся из невероятного поездки на побережье! Исследовали местные рынки, отдыхали на уединенных пляжах, ходили на восхитительные виды, каякерили в морские пещеры и наслаждались свежей рыбой и премиальными винами. Очень освежающий перерыв от повседневной суеты!"
    },
    {
      language: "Arabic",
      text: "الرسالة الملخصة: عودت من رحلة رائعة على الساحل! استكشفنا الأسواق المحلية، واسترخينا على الشواطئ النائية، وتسلقنا إلى قمم الجبال، وقمنا برحلات كاياك في الكهوف البحرية، وتناولنا طعام البحر鲜ي جيد التحضير. كانت فرصة استرخاء رائعة من ضجة الحياة اليومية."
    },
    {
      language: "Portuguese",
      text: "Resumo da Mensagem: Acabei de voltar de uma viagem incrível à beira-mar! Exploramos mercados locais, relaxamos em praias isoladas, fizemos trilhas em montanhas, fizemos kayak em cavernas marítimas e comemos peixe fresco bem preparado. Foi uma pausa rejuvenescedora da agitação da vida diária."
    },
    {
      language: "Chinese",
      text: "消息摘要: 这位朋友刚从一次令人难忘的海滩之旅回来。他们在一个迷人的海滨小镇上,探索当地市场,品尝美味的羊角面包。他们还去了一处隐秘的白沙滩,在那里享受阳光和清澈的大海。他们还徒步到附近山丘的顶部,欣赏整个海岸线的绝美景色。另外,他们还租了皮艇,探索了沿岸的海蚀洞穴,看到了海豹。晚上,他们在渔人码头吃了新鲜的鱼塔可。当地人还推荐了一个星空观赏的好去处。整个旅行使他们远离繁忙的生活,得到了放松和重振精神。他们还参观了当地的葡萄园,品尝了那里赢得大奖的美酒。总之,这是一次非同寻常的度假体验。"
    },
    {
      language: "Japanese",
      text: "メッセージの要約: このメッセージは、話し手が最近した素晴らしい旅行の経験を述べています。小さな海辺の街を散策し、地元の市場で新鮮な食べ物を購入し、秘境のビーチでくつろぎ、丘の上から海岸線の素晴らしい景色を楽しみ、シーカヤックで洞窟を探検し、新鮮な魚介料理を味わい、ワイナリーでワインを試飲するなど、リラックスできる思い出深い体験を味わいました。自然と文化に触れる素晴らしい休暇だったようです。"
    },
    {
      language: "Polish",
      text: "Podsumowanie wiadomości: Wróciłem z niezapomnianej podróży nad morze! Zwiedziliśmy lokalne targi, relaksowaliśmy się na odosobnionych plażach, wędrowaliśmy po górach, pływaliśmy kajakiem po jaskiniach morskich i jedliśmy świeżą rybę doskonale przygotowaną. Był to odświeżający odpoczynek od zgiełku życia codziennego."
    },
    {
      language: "Hindi",
      text: "संदेश सारांश: मैं अभी इस अविश्वसनीय यात्रा से लौटा हूँ! हमने स्थानीय बाजारों का पता लगाया, अलग-थलग समुद्र तटों पर आराम किया, पास के पहाड़ पर चढ़ाई की, समुद्री गुफाओं में कयाकिंग की और ताजा मछली खाई। यह दैनिक जीवन के हलचल से एक सुस्ती भरा ब्रेक था।"
    },
    {
      language: "Turkish",
      text: "Mesaj Özeti: İnanılmaz bir yolculuktan yeni döndüm! Yerel pazarları keşfettik, ıssız plajlarda dinlendik, yakın bir tepenin dik yolunu tırmandık, deniz mağaralarında kano yaptık ve taze balık yedik. Günlük hayatın telaşesinden bir dinlenme fırsatıydı."
    },
    {
      language: "French",
      text: "Résumé du message: Je viens de rentrer d'un voyage incroyable ! Nous avons exploré les marchés locaux, nous avons passé des heures à nous détendre sur des plages isolées, nous avons fait de la randonnée dans les collines voisines, nous avons fait du kayak dans des grottes marines et nous avons mangé du poisson frais délicieux. C'était une pause rafraîchissante loin de l'agitation de la vie quotidienne."
    }
]

  useEffect(() => {
    // scroll to the top of the page on render
    // for some reason scroll to 0,0 wasn't putting users at the top of the page
    window.scrollTo(0, -500);
  }, [])

  function play_audio() {
		let audio = document.getElementById("demo-audio") as any;
		audio.play();
    setPaused(false);
	}

	function pause_audio() {
		let audio = document.getElementById("demo-audio") as any;
		audio.pause();
    setPaused(true);
	}

	function restart_audio() {
		let audio = document.getElementById("demo-audio") as any;
		audio.currentTime = 0
		audio.play()
    setPaused(false);
	}

  function webgl_support () { 
    try {
      var canvas = document.createElement('canvas'); 
      var webglContext =  !!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
      var webglEnabled = webglContext !== null;

      if(webglEnabled) {
        console.log("WebGL is enabled.");
      }
      else {
        console.log("WebGL is not enabled.");
      }
      
      return webglEnabled;
    } catch(e) {
      return false;
    }
  };

  function SummaryCard(props: any)
  {
    return (
      <Box className="summary-box">
        <Card className="summary-card">
            <Typography variant="h5" className="summary-title">{props.item.language}</Typography>
            <Box className="summary-text-box">
              <Typography className="summary-text" variant="body1">
                {props.item.text}
              </Typography>
            </Box>
        </Card>
      </Box>
    )
  }
  
  return (

    <div className="page-body">
      <Box className="bluf-statement">
        <div className="bluf-statement-background"/>
        <Card className="bluf-statement-card">
          <Typography className="bluf-text" variant="h3" component="h1">
            {isLoggedIn? `Welcome ${user.firstName}!` : "Welcome,"}
          </Typography>
          <Typography className="bluf-text" variant="subtitle1" component="h1">
            <b>Do you have friends who speak other languages?</b>
          </Typography>
          <Typography className="bluf-text" variant="subtitle1" component="h1">
            <b>Introducing Abreviado:</b> Your automated stenographer who can translate and summarize audio files and voice messages from 50+ different langauges.
          </Typography>
        </Card>
      </Box>

      <Box className="audio-demo">
        <audio id="demo-audio" ref={audioRef} src={sound} />
        {webgl_support()?
          <Equalizer amplitude={9} cameraFov={20} gridCols={30} gridRows={8} cubeSideLength={0.6} cubeSpacing={10} cameraPosition={[50, 0, 6]} audio={audioRef} />:
          <Typography className="audio-title-no-webgl" variant="h4" component="h4">
            Audio Message <GraphicEq className="audio-icon-no-webgl"/>
          </Typography>
        }

        <div>
          {paused? 
            <Button className="audio-button" onClick={play_audio}>
              <PlayCircleOutline/> &nbsp; Play
            </Button> :
            <Button className="audio-button" onClick={pause_audio}>
              <PauseCircleOutline/> &nbsp; Pause
            </Button>
          }
          <Button className="restart-button" onClick={restart_audio}>
            <Replay/> &nbsp; Replay
          </Button>
        </div>
      </Box>

      <Box className="text-carousel">
        <Carousel animation="slide" duration={1200} indicators={false}>
          {
              items.map( (item, i) => <SummaryCard key={i} item={item} /> )
          }
        </Carousel>
      </Box>

      <Box className="whatsapp-info">
        <Box className="whatsapp-info-subsection">
          <Typography className="whatsapp-info-title" variant="h4" component="h1">
            WhatsApp Integration
          </Typography>
          <Typography className="whatsapp-info-text" variant="body1" component="h1">
            Simply <b>forward</b> your audio messages to the <b>Abreviado</b> WhatsApp account to receive automatic translations and summarizations to your <b>native language</b> in as little as <b>60 seconds.</b>
          </Typography>
        </Box>
        <img className="whatsapp-example-image" alt="Translation and summarization ocurring directly in WhatsApp." src="./images/home-page/whatsapp-example.png"/>
      </Box>
      
      <Box className="contact">
        <Typography className="contact-text" variant="h5" component="h1">
          <b>Interested In Learning More?</b>
        </Typography>
        <img className="contact-image" alt="Contact us today." src="./images/home-page/contact-us.png"/>
        <Button className="contact-button" href={`mailto:penn@nocomment.dev`}>
          Contact Us Today
        </Button>
      </Box>
    </div>
  );
}

export default HomePage;
