import { Box, Typography } from '@mui/material';

import './FooterComponent.css';

function FooterComponent() {
  
  return (
    <Box className="footer">
      <Typography className="footer-text" variant="body1" component="div">
        © 2024 Abreviado
      </Typography>
      <Typography className="footer-sub-text" variant="body2" component="div">
        Developed by <b>//NoComment</b>
      </Typography>
    </Box>
  );
}

export default FooterComponent;
