import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from "../utils/AxiosInterceptor"

import SurveyModel from '../model/SurveyModel';

interface SurveyDetailsState {
  survey: SurveyModel | null;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: SurveyDetailsState = {
  survey: null,
  loading: false,
  error: null
};

// Methed to retrieve a survey details with a given ID
// Thunks can only take one parameter so they have to be restructured
// TODO: redo this once we have a global axios handler
export const getSurveyDetails = createAsyncThunk('surveyDetails/get', async (parameters:any) => {
  const {authToken, surveyId} = parameters;

  let accessToken = authToken.accessToken;
  let tokenType = authToken.tokenType;

  axiosInstance.interceptors.request.use( config => {
    // Add the access token if we have it
    config.headers.Authorization =  tokenType + " " + accessToken;

    // return the updated config
    return config;
    
  }, error => {
    return Promise.reject(error)
  });

  const response = await axiosInstance.get('abreviado/api/survey/full/' + surveyId);
  return response.data;
});

// Methed to clear the user
export const clearSurveyDetails = createAsyncThunk('surveyDetails/clear', async () => {
  return null;
});

const surveyDetailsSlice: any = createSlice({
  name: 'surveyDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.survey = null;
      })
      .addCase(getSurveyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.survey = action.payload;
        state.error = null;
      })
      .addCase(getSurveyDetails.rejected, (state, action) => {
        state.loading = false;
        state.survey = null;
        state.error = action.error.message || 'An error occured while retrieving the survey details';
      });
  }
});

export default surveyDetailsSlice.reducer;