import { Box } from '@mui/material';
import FacebookLogin from '@greatsumini/react-facebook-login';
import './WhatsappIntegrationTestPage.css';
import { useState } from 'react';

function WhatsappIntegrationTestPage() {

  const handleFacebookLoginSuccessCallback = (response : any) => {
    if (response?.status === "unknown") {
        console.error('Sorry!', 'Something went wrong with facebook Login.');
     return;
    }
    console.log('successful login', response);
   }

  const handleFacebookLoginFailCallback = (error : any) => {
  if (error?.status === "unknown") {
      console.error('Sorry!', 'Something went wrong with facebook Login.');
      console.log('login error', error)
    return;
  }
  console.log(error);
  }

  const handleFacebookProfileSuccessCallback = (response : any) => {
  if (response?.status === "unknown") {
      console.error('Sorry!', 'Something went wrong with facebook Login.');
    return;
  }
  console.log('successful profile', response);
  }


  return (
    <FacebookLogin 
      style={{
        backgroundColor: '#4267b2',
        color: '#fff',
        fontSize: '16px',
        padding: '12px 24px',
        border: 'none',
        borderRadius: '4px',
      }}  
      appId="806534311606315"
      autoLoad={false}  
      fields="name,email,picture"  
      onSuccess={handleFacebookLoginSuccessCallback}
      onFail={handleFacebookLoginFailCallback}
      onProfileSuccess={handleFacebookProfileSuccessCallback}/>
  );
}

export default WhatsappIntegrationTestPage;