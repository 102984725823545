import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from "../utils/AxiosInterceptor"

import UserSurveyModel from '../model/UserSurveyModel';

interface UserSurveyState {
  userSurvey: UserSurveyModel | null;
  surveySubmissionId: number | null;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: UserSurveyState = {
  userSurvey: null,
  surveySubmissionId: null,
  loading: false,
  error: null
};

// Methed to retrieve a survey details with a given ID
// Thunks can only take one parameter so they have to be restructured
// TODO: redo this once we have a global axios handler
export const submitSurvey = createAsyncThunk('userSurvey/submit', async (parameters:any) => {
  const {authToken, surveyRequest} = parameters;

  let accessToken = authToken.accessToken;
  let tokenType = authToken.tokenType;

  axiosInstance.interceptors.request.use( config => {
    // Add the access token if we have it
    config.headers.Authorization =  tokenType + " " + accessToken;

    // return the updated config
    return config;
    
  }, error => {
    return Promise.reject(error)
  });

  const response = await axiosInstance.post('abreviado/api/survey/submit', surveyRequest);
  
  //return only the id from the response data
  return response.data.id;
});

export const getUserSurvey = createAsyncThunk('userSurvey/get', async (parameters:any) => {
  const {authToken, userSurveyId} = parameters;

  let accessToken = authToken.accessToken;
  let tokenType = authToken.tokenType;

  axiosInstance.interceptors.request.use( config => {
    // Add the access token if we have it
    config.headers.Authorization =  tokenType + " " + accessToken;

    // return the updated config
    return config;
    
  }, error => {
    return Promise.reject(error)
  });

  const response = await axiosInstance.get('abreviado/api/user-survey/' + userSurveyId);
  
  //return only the id from the response data
  return response.data;
});

// Methed to clear the user
export const clearUserSurvey = createAsyncThunk('userSurvey/clear', async () => {
  return null;
});

// Methed to clear the user
export const clearSurveySubmissionId = createAsyncThunk('userSurvey/clearSubmission', async () => {
  return null;
});

const userSurveySlice: any = createSlice({
  name: 'surveyDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitSurvey.pending, (state) => {
        state.loading = true;
        state.userSurvey = null;
        state.surveySubmissionId = null;
        state.error = null;
      })
      .addCase(submitSurvey.fulfilled, (state, action) => {
        state.loading = false;
        state.userSurvey = null;
        state.surveySubmissionId = action.payload;
        state.error = null;
      })
      .addCase(submitSurvey.rejected, (state, action) => {
        state.loading = false;
        state.userSurvey = null;
        state.surveySubmissionId = null;
        state.error = action.error.message || 'An error occured while submitting the survey';
      })
      .addCase(getUserSurvey.pending, (state) => {
        state.loading = true;
        state.userSurvey = null;
        state.surveySubmissionId = null;
        state.error = null;
      })
      .addCase(getUserSurvey.fulfilled, (state, action) => {
        state.loading = false;
        state.userSurvey = action.payload;
        state.surveySubmissionId = null;
        state.error = null;
      })
      .addCase(getUserSurvey.rejected, (state, action) => {
        state.loading = false;
        state.userSurvey = null;
        state.surveySubmissionId = null;
        state.error = action.error.message || 'An error occured while submitting the survey';
      })
      .addCase(clearSurveySubmissionId.pending, (state) => {
        state.loading = true;
        state.userSurvey = null;
        state.surveySubmissionId = null;
        state.error = null;
      })
      .addCase(clearSurveySubmissionId.fulfilled, (state, action) => {
        state.loading = false;
        state.userSurvey = null;
        state.surveySubmissionId = null;
        state.error = null;
      })
      .addCase(clearSurveySubmissionId.rejected, (state, action) => {
        state.loading = false;
        state.userSurvey = null;
        state.surveySubmissionId = null;
        state.error = action.error.message || 'An error occured while clearing the survey';
      });
  }
});

export default userSurveySlice.reducer;